import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogService } from 'cui-components';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialogs';
import * as fromActions from './environments.actions';
import { EnvironmentsService } from './environments.service';

@Injectable()
export class EnvironmentsEffects {
  onGetEnvironments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllEnvironments),
      switchMap(({ coreCustomerId }) =>
        this.environmentsService.getAll(coreCustomerId).pipe(
          map(environments => fromActions.getAllEnvironmentsComplete({ environments })),
          catchError(err => of(fromActions.getAllEnvironmentsError({ err }))),
        ),
      ),
    ),
  );

  onSelectEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectEnvironment),
      switchMap(({ environmentId }) =>
        this.environmentsService.getOne(environmentId).pipe(
          map(environment => fromActions.selectEnvironmentComplete({ environment })),
          catchError(err => of(fromActions.selectEnvironmentError({ err }))),
        ),
      ),
    ),
  );

  onGoToList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToList),
      tap(() => this.router.navigate(['environments'])),
      map(() => fromActions.resetState({ selectedEnvironment: null })),
    ),
  );

  onSetEnvironmentUpdateDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEnvironmentUpdateDate),
      switchMap(({ environmentId, runOnDate, runOnTime, ignoreUpgradeWindow }) =>
        this.environmentsService
          .setUpdateDate(environmentId, {
            runOn: this.getISOString(runOnDate!, runOnTime),
            ignoreUpgradeWindow,
          })
          .pipe(
            map(res => fromActions.setEnvironmentUpdateDateComplete({ environmentId, res })),
            catchError(err => of(fromActions.setEnvironmentUpdateDateError({ err }))),
          ),
      ),
    ),
  );

  onSetEnvironmentUpdatDateComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.setEnvironmentUpdateDateComplete),
        tap(({ environmentId }) => {
          this.router.navigate(['environments', environmentId]);
          this.dialogService.openDialog<ConfirmationDialogData, undefined>(ConfirmationDialogComponent, {
            title: 'Set Update Date',
            description: 'Request to modify update date added to queue. Changes will be visible once request completes successfully.',
            cancelText: 'OK',
            confirmText: '',
            showCloseBtn: false,
          });
        }),
      ),
    { dispatch: false },
  );

  onSetEnvironmentUpdateWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEnvironmentUpdateWindow),
      switchMap(({ environmentId, preferredStartTime, preferredEndTime }) =>
        this.environmentsService
          .setUpdateWindow(environmentId, {
            preferredStartTimeUtc: `${this.getTimeInUtcString(preferredStartTime)}:00.000`,
            preferredEndTimeUtc: `${this.getTimeInUtcString(preferredEndTime)}:00.000`,
          })
          .pipe(
            map(res => fromActions.setEnvironmentUpdateWindowComplete({ environmentId, res })),
            catchError(err => of(fromActions.setEnvironmentUpdateWindowError({ err }))),
          ),
      ),
    ),
  );

  onSetEnvironmentUpdateWindowComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.setEnvironmentUpdateWindowComplete),
        tap(({ environmentId }) => {
          this.router.navigate(['environments', environmentId]);
          this.dialogService.openDialog<ConfirmationDialogData, undefined>(ConfirmationDialogComponent, {
            title: 'Set Update Window',
            description: 'Request to modify update window added to queue. Changes will be visible once request completes successfully.',
            cancelText: 'OK',
            confirmText: '',
            showCloseBtn: false,
          });
        }),
      ),
    { dispatch: false },
  );

  getTimeInUtcString(localTime: string): string {
    return getDateTimeFromTime(localTime).toISOString().split('T')[1].slice(0, 5);
  }

  getISOString(date: Date, time: string): string {
    const [hours, minutes] = time.split(':');
    date.setHours(+hours);
    date.setMinutes(+minutes);
    return date.toISOString();
  }

  constructor(
    private readonly actions$: Actions,
    private readonly environmentsService: EnvironmentsService,
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {}
}

export function getDateTimeFromTime(time: string): Date {
  const dateTime = new Date();
  const [hours, minutes] = time.split(':');
  dateTime.setHours(+hours);
  dateTime.setMinutes(+minutes);
  dateTime.setSeconds(0);
  dateTime.setMilliseconds(0);
  return dateTime;
}
