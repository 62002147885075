import { NewsArticleListItem } from '../app-state/news-articles/news-article.model';

export const isAvailableForPartnerByMembership = (
  newsArticle: NewsArticleListItem,
  regionId: string,
  membershipLevelId: string,
): boolean => {
  const regionEntry = newsArticle.regions.find(item => item.id === regionId);
  if (regionEntry) {
    return regionEntry.membershipLevels.indexOf(membershipLevelId) > -1;
  }
  return false;
};
