import { createReducer, on } from '@ngrx/store';
import { findAndRemove, sortItems } from '../helpers/misc';
import * as fromActions from './customer-report.actions';
import { CustomerReport, CustomerReportLine, ReportedCustomer } from './customer-report.model';

export interface State {
  isLoading: boolean;
  customerReportCreateLoading: boolean;
  customerReport: CustomerReport | null;

  reportedCustomersLoading: boolean;
  reportedCustomers: ReportedCustomer[];

  submitCustomerReportLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  customerReportCreateLoading: false,
  customerReport: null,

  reportedCustomersLoading: false,
  reportedCustomers: [],

  submitCustomerReportLoading: false,
};

export const customerReportReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getCustomerReport, state => ({ ...state, isLoading: true })),
  on(fromActions.getCustomerReportComplete, (state, { customerReport }) => ({
    ...state,
    customerReport: customerReport
      ? {
          ...customerReport,
          lines: sortItems<CustomerReportLine>(customerReport.lines, (a, b) => a.customerName.localeCompare(b.customerName)),
        }
      : customerReport,
    isLoading: false,
  })),
  on(fromActions.getCustomerReportError, state => ({ ...state, isLoading: false })),

  on(fromActions.getReportedCustomers, state => ({ ...state, reportedCustomersLoading: true })),
  on(fromActions.getReportedCustomersComplete, (state, { reportedCustomers }) => ({
    ...state,
    reportedCustomers: sortItems<ReportedCustomer>(reportedCustomers, sortByNameAndDate),
    reportedCustomersLoading: false,
  })),
  on(fromActions.getReportedCustomersError, state => ({ ...state, reportedCustomersLoading: false })),

  on(fromActions.submitCustomerReport, state => ({ ...state, submitCustomerReportLoading: true, isLoading: true })),
  on(fromActions.submitCustomerReportComplete, (state, { customerReport }) => ({
    ...state,
    customerReport,
    submitCustomerReportLoading: false,
    isLoading: false,
  })),
  on(fromActions.submitCustomerReportError, state => ({ ...state, submitCustomerReportLoading: false, isLoading: false })),

  on(fromActions.addCustomerReportLine, state => ({ ...state, isLoading: true })),
  on(fromActions.addCustomerReportLineComplete, (state, { customerReport }) => ({
    ...state,
    isLoading: false,
    customerReport: {
      ...customerReport,
      lines: sortItems<CustomerReportLine>(customerReport.lines, (a, b) => a.customerName.localeCompare(b.customerName)),
    },
  })),
  on(fromActions.addCustomerReportLineError, state => ({ ...state, isLoading: false })),

  on(fromActions.deleteCustomerReportLine, state => ({ ...state, isLoading: true })),
  on(fromActions.deleteCustomerReportLineComplete, (state, { lineId }) => ({
    ...state,
    isLoading: false,
    customerReport: state.customerReport
      ? {
          ...state.customerReport,
          lines: findAndRemove<CustomerReportLine>(state.customerReport.lines, line => line.id === lineId),
        }
      : state.customerReport,
  })),
  on(fromActions.deleteCustomerReportLineError, state => ({ ...state, isLoading: false })),
);

export const sortByNameAndDate = (a: ReportedCustomer, b: ReportedCustomer): number => {
  if (a.reportPeriodFrom < b.reportPeriodFrom) {
    return 1;
  }
  if (a.reportPeriodFrom > b.reportPeriodFrom) {
    return -1;
  }
  return a.customerName.localeCompare(b.customerName);
};
