import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { commonDataActions, newsArticlesActions, newsArticlesSelectors, partnersActions, partnersSelectors } from '@appState';
import { select, Store } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { combineLatest, filter, tap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { isAvailableForPartnerByMembership } from '../../util';

@Injectable()
export class NewsArticleViewResolver {
  constructor(private readonly store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('articleId')!;

    this.store.dispatch(commonDataActions.getCommonDataList({ dataType: 'topic' }));

    return combineLatest({
      partner: this.store.pipe(
        select(partnersSelectors.selectCurrentPartner),
        tap(partner =>
          partner
            ? this.store.dispatch(newsArticlesActions.getOpenedNewsArticle({ id, regionId: partner.region }))
            : this.store.dispatch(partnersActions.getPartner()),
        ),
        filter(isNotNullOrUndefined),
      ),
      article: this.store.pipe(select(newsArticlesSelectors.selectOpenedNewsArticle), filter(isNotNullOrUndefined)),
    }).pipe(
      tap(({ partner, article }) => {
        if (isAvailableForPartnerByMembership(article, partner.region, partner.membershipLevel)) {
          this.store.dispatch(newsArticlesActions.getAllNewsArticleComments({ articleId: id, regionId: partner.region }));
        }
      }),
    );
  }
}
