import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.customerReport);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectCustomerReport = createSelector(selectModuleState, state => state.customerReport);

export const selectReportedCustomers = createSelector(selectModuleState, state => state.reportedCustomers);
export const reportedCustomersLoading = createSelector(selectModuleState, state => state.reportedCustomersLoading);
