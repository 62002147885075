import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddCustomerReportLineRequest, CustomerReport, ReportedCustomer } from './customer-report.model';

@Injectable()
export class CustomerReportService {
  constructor(private highlanderService: HighlanderService) {}

  getReportedCustomers(): Observable<ReportedCustomer[]> {
    return this.highlanderService.get<ReportedCustomer[]>('reported-customers');
  }

  getCustomerReport(): Observable<CustomerReport> {
    return this.highlanderService.get<CustomerReport>('customer-report');
  }

  submitCustomerReport(id: string): Observable<CustomerReport> {
    return this.highlanderService.post<undefined, CustomerReport>(`customer-report/${id}/submit`);
  }

  addCustomerReportLine(id: string, data: AddCustomerReportLineRequest[]): Observable<CustomerReport> {
    return this.highlanderService.post<AddCustomerReportLineRequest[], CustomerReport>(`customer-report/${id}/lines`, data);
  }

  deleteCustomerReportLine(id: string, lineId: string): Observable<void> {
    return this.highlanderService.delete(`customer-report/${id}/lines/${lineId}`);
  }
}
