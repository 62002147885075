<header class="header">
  <h1>Welcome to the Companial Partner Portal – Your One-Stop Hub for Companial Services</h1>
  <p>
    This portal centralizes all essential services and information for Companial partners, streamlining your licensing, technical services,
    and support operations to help you serve your customers more easily and efficiently.
  </p>
</header>

<main
  *ngIf="{
    hasSupportMenuAccess: hasSupportMenuAccess$ | async,
    hasInformationHubAndNewsAccess: hasInformationHubAndNewsAccess$ | async,
    hasMMMenuAccess: hasMMMenuAccess$ | async,
    hasSPMenuAccess: hasSPMenuAccess$ | async,
    hasProjectMenuAccess: hasProjectMenuAccess$ | async
  } as permissions"
  class="main-content"
>
  <section>
    <h3>Here you'll find (on the left side menu):</h3>
    <div class="tiles">
      <a
        class="tile"
        [ngClass]="{ hoverable: permissions.hasSupportMenuAccess }"
        [routerLink]="permissions.hasSupportMenuAccess ? '/support' : null"
      >
        <span class="icon support-icon"></span>
        <h2>Support</h2>
        <p>Submit, review, and manage support cases to get the assistance you need.</p>
      </a>
      <a
        class="tile"
        [ngClass]="{ hoverable: permissions.hasInformationHubAndNewsAccess }"
        [routerLink]="permissions.hasInformationHubAndNewsAccess ? '/news-articles' : null"
      >
        <span class="icon info-hub-icon"></span>
        <h2>Information Hub</h2>
        <p>
          Stay updated on Microsoft licensing, incentives, programs, and technology changes with easy access to essential resources and the
          latest updates.
        </p>
      </a>
      <a
        class="tile"
        [ngClass]="{ hoverable: permissions.hasSPMenuAccess }"
        [href]="permissions.hasSPMenuAccess ? '/selfprovisioning' : '#'"
        [style.pointer-events]="permissions.hasSPMenuAccess ? 'auto' : 'none'"
      >
        <span class="icon self-provisioning-icon"></span>
        <h2>Hosting (Self-Provisioning)</h2>
        <p>Deploy and manage customer solutions seamlessly on Microsoft Azure with our easy-to-use platform.</p>
      </a>
      <a
        class="tile"
        [ngClass]="{ hoverable: permissions.hasProjectMenuAccess }"
        [routerLink]="permissions.hasProjectMenuAccess ? '/project/list' : null"
      >
        <span class="icon projects-icon"></span>
        <h2>Projects</h2>
        <p>
          Request analysis and guidance for Microsoft Dynamics Business Central upgrade projects, choose the ideal upgrade path, and receive
          a detailed proposal.
        </p>
      </a>
      <a
        class="tile"
        [ngClass]="{ hoverable: permissions.hasMMMenuAccess }"
        [routerLink]="permissions.hasMMMenuAccess ? '/mm-program' : null"
      >
        <span class="icon mm-icon"></span>
        <h2>Migration and Modernization Program</h2>
        <p>
          Access unlimited online assessments, specialized training, and technical tools to ensure a seamless transition from Dynamics NAV
          on-premises clients to the cloud.
          <ng-container *ngIf="!permissions.hasMMMenuAccess">
            Have membership but not participating in Microsoft Migration and Modernization program?
            <a [routerLink]="'/join-mm-program'">Join here</a>.</ng-container
          >
        </p>
      </a>
    </div>
    <p>
      If any of the above functionalities are not yet enabled for you, please contact the
      <a [routerLink]="'/settings/company-administrators'" href="javascript:void(0);">admin assigned by your organization</a> or reach out
      to our <a href="mailto:loginsupport@companial.com">Partner Portal support</a> team. Please note that some content or offerings are
      accessible only to Companial members. To learn more about becoming a member, please
      <a href="https://companial.com/why-a-membership-pays-off/">visit this page</a>.
    </p>
  </section>

  <section>
    <h3>From here, you can also access:</h3>
    <div class="additional-links">
      <div class="link-box">
        <h3><a href="https://academy.companial.com/">Companial Academy:</a></h3>
        <p>Develop your team’s technical and soft skills to ensure continuous learning and maintain competitiveness.</p>
      </div>
      <div class="link-box">
        <h3><a href="https://global.cspprod.com">CSP Marketplace:</a></h3>
        <p>
          Access the end-customer marketplace to streamline your Microsoft CSP products transactions, including licensing ordering,
          provisioning, automated billing and reporting.
        </p>
      </div>
      <div class="link-box">
        <h3><a href="https://companial.com/solutions-marketplace/">ISV Solution Marketplace:</a></h3>
        <p>
          Enhance your portfolio by accessing a diverse range of ISV solutions – from AP automation and e-billing to specialized tools for
          procurement and HR.
        </p>
      </div>
    </div>
  </section>
</main>

<footer>
  <p>
    If you would like to know more, need assistance, or have any other questions, please contact your local
    <a href="https://companial.com/contact-us/">Companial representative</a> or get in touch with our
    <a href="mailto:loginsupport@companial.com">Partner Portal support</a>.
  </p>
</footer>
