import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnsubscribeData, UserNotificationSub } from './notification-subs.model';

@Injectable({ providedIn: 'root' })
export class UserNotificationSubsService {
  private readonly path = (url = ''): string => `notifications/subscriptions${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getSubscriptions(): Observable<UserNotificationSub[]> {
    return this.highlanderService.get<UserNotificationSub[]>(this.path());
  }

  patchSubscriptions(subscriptions: UserNotificationSub[]): Observable<UserNotificationSub[]> {
    return this.highlanderService.patch<UserNotificationSub[], UserNotificationSub[]>(this.path(), subscriptions);
  }

  getUnsubscribeData(data: string): Observable<UnsubscribeData> {
    return this.highlanderService.get<UnsubscribeData>(this.path('/unsubscribe-data'), { params: { data } });
  }

  unsubscribeFromNotification(data: string): Observable<void> {
    return this.highlanderService.post<void, void>(this.path('/unsubscribe'), undefined, { params: { data } });
  }
}
