import { createReducer, on } from '@ngrx/store';
import * as fromActions from './notification-subs.actions';
import { UnsubscribeData, UserNotificationSub } from './notification-subs.model';
export interface State {
  isLoading: boolean;
  subs: UserNotificationSub[];

  isLoadingUnsubscribeData: boolean;
  unsubscribeData: UnsubscribeData | null;
}

export const initialState: State = {
  isLoading: false,
  subs: [],

  isLoadingUnsubscribeData: false,
  unsubscribeData: null,
};

export const notificationSubsReducer = createReducer(
  initialState,
  on(fromActions.query, state => ({ ...state, isLoading: true })),
  on(fromActions.queryComplete, (state, { subs }) => ({ ...state, subs, isLoading: false })),
  on(fromActions.queryError, state => ({ ...state, isLoading: false })),

  on(fromActions.subscribeChange, state => ({ ...state, isLoading: true })),
  on(fromActions.subscribeChangeComplete, (state, { subs }) => ({ ...state, subs, isLoading: false })),
  on(fromActions.subscribeChangeError, state => ({ ...state, isLoading: false })),

  on(fromActions.getUnsubscribeData, state => ({ ...state, isLoadingUnsubscribeData: true })),
  on(fromActions.getUnsubscribeDataComplete, (state, { unsubscribeData }) => ({
    ...state,
    unsubscribeData,
    isLoadingUnsubscribeData: false,
  })),
  on(fromActions.getUnsubscribeDataError, state => ({ ...state, isLoadingUnsubscribeData: false })),

  on(fromActions.unsubscribeFromNotification, state => ({ ...state, isLoadingUnsubscribeData: true })),
  on(fromActions.unsubscribeFromNotificationComplete, state => ({ ...state, isLoadingUnsubscribeData: false })),
  on(fromActions.unsubscribeFromNotificationError, state => ({ ...state, isLoadingUnsubscribeData: false })),
);
