import { manageCurrentAddressSessionStorage } from '@1clickfactory/common';
import { SubscribingComponent } from '@1clickfactory/common/base-component/subscribing.component';
import { ConfigService } from '@1clickfactory/common/config/app-config.service';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieAuth } from '@highlander/common/helpers/authentication';
import { Store, select } from '@ngrx/store';
import { NavExpandNode, isNotNullOrUndefined } from 'cui-components';
import { Observable, combineLatest, filter, map, mergeMap, takeUntil } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { Identity, identityActions, identitySelectors, impersonationActions, impersonationSelectors, pageSelectors } from '../../app-state';

const PAGES_WITH_CLOSE_BTN = ['Users'];

type PageData = { returnUrl: string | undefined; pageTitle: string };

@Component({
  selector: 'ocf-authenticated-template-component',
  templateUrl: './authenticated-template.component.html',
  styleUrls: ['./authenticated-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticatedTemplateComponent extends SubscribingComponent implements OnInit {
  hasProjectMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Projects.MenuAccess'])),
  );
  hasSPMenuAccess$: Observable<boolean> = this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.SP.MenuAccess'])));
  hasShoppingCartMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.ShoppingCart.MenuAccess'])),
  );

  hasInformationHubMenuAccess$: Observable<boolean>;
  hasSaasMenuAccess$: Observable<boolean>;
  hasMMMenuAccess$: Observable<boolean>;

  navExpandInformationHub: NavExpandNode[] = [
    {
      id: '1',
      parentId: null,
      name: 'Information Hub',
      icon: 'menu',
      children: [],
    },
  ];

  navExpandSaas: NavExpandNode[] = [
    {
      id: '4',
      parentId: null,
      name: 'SaaS',
      icon: 'troubleshoot',
      children: [],
    },
  ];

  navExpandMMProgram: NavExpandNode[] = [
    {
      id: '6',
      parentId: null,
      name: 'MM Program',
      icon: 'vertical_align_top',
      children: [],
    },
  ];

  hasSupportMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Support.MenuAccess'])),
  );
  hasImpersonationMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Impersonation.MenuAccess'])),
  );
  hasSettingsMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Settings.MenuAccess'])),
  );
  hasCustomersMenuAccess$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.Customers.MenuAccess'])),
  );

  user$: Observable<Identity> = this.store.pipe(select(identitySelectors.selectIdentity), filter(isNotNullOrUndefined));

  impersonatedPartner$ = this.store.pipe(select(impersonationSelectors.selectImpersonatedPartner));
  isLoadingPermissions$: Observable<boolean> = this.store.pipe(select(identitySelectors.selectIsLoading));
  text$: Observable<string> = this.store.pipe(select(pageSelectors.getTopText));
  bottomText$: Observable<string> = this.store.pipe(select(pageSelectors.getBottomText));
  topBgColor: string;
  PAGES_WITH_CLOSE_BTN = PAGES_WITH_CLOSE_BTN;

  pageData!: { returnUrl: string | undefined; pageTitle: string };

  url$: Observable<string> = new Observable<string>();

  @HostBinding('attr.id') id = 'highlander';

  constructor(
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly appConfig: ConfigService,
  ) {
    super();
    this.topBgColor = appConfig.config['FRONTEND_TOP_MENU_BG_COLOR'] ?? '#d4d4d4';
    this.route.firstChild!.data.pipe(takeUntil(this.destroyed$)).subscribe(data => (this.pageData = data as PageData));

    this.hasInformationHubMenuAccess$ = combineLatest({
      hasInformationHubMenuAccess: this.store.pipe(
        select(identitySelectors.selectHasPermission(['Portal.Business.InformationHub.MenuAccess'])),
      ),
      hasDocumentsAccess: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.Documents.MenuAccess']))),
      hasNewsAccess: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.NewsArticles.MenuAccess']))),
    }).pipe(
      select(({ hasInformationHubMenuAccess, hasDocumentsAccess, hasNewsAccess }) => {
        if (hasNewsAccess) {
          this.navExpandInformationHub[0].children = [
            ...this.navExpandInformationHub[0].children!,
            {
              name: 'News',
              parentId: '1',
              id: '3',
              url: '/news-articles',
              icon: 'description',
            },
          ];
        }
        if (hasDocumentsAccess) {
          this.navExpandInformationHub[0].children = [
            ...this.navExpandInformationHub[0].children!,
            {
              name: 'Documents',
              parentId: '1',
              id: '2',
              url: '/documents',
              icon: 'source',
            },
          ];
        }
        return hasInformationHubMenuAccess;
      }),
    );

    this.hasSaasMenuAccess$ = combineLatest({
      hasSaasMenuAccess: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.Saas.MenuAccess']))),
      hasEnvironmentsAccess: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.Environments.MenuAccess']))),
    }).pipe(
      select(({ hasSaasMenuAccess, hasEnvironmentsAccess }) => {
        if (hasEnvironmentsAccess) {
          this.navExpandSaas[0].children = [
            ...this.navExpandSaas[0].children!,
            {
              name: 'Environments',
              parentId: '4',
              id: '5',
              url: '/environments',
              icon: 'menu',
            },
          ];
        }

        return hasSaasMenuAccess;
      }),
    );

    this.hasMMMenuAccess$ = combineLatest({
      hasMMMenuAccess: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.MM.MenuAccess']))),
      hasReportedCustpmersAccess: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.MM.ReportedCustomers']))),
      hasCustomerReportAccess: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.MM.CustomerReport']))),
    }).pipe(
      select(({ hasMMMenuAccess, hasReportedCustpmersAccess, hasCustomerReportAccess }) => {
        if (hasMMMenuAccess) {
          this.navExpandMMProgram[0].children = [
            ...this.navExpandMMProgram[0].children!,
            {
              name: 'Assessments',
              parentId: '6',
              id: '7',
              url: '/mm-program',
              icon: 'assignment',
            },
          ];
        }
        if (hasReportedCustpmersAccess) {
          this.navExpandMMProgram[0].children = [
            ...this.navExpandMMProgram[0].children!,
            {
              name: 'Reported Customers',
              parentId: '6',
              id: '8',
              url: '/reported-customers',
              icon: 'flag',
            },
          ];
        }
        if (hasCustomerReportAccess) {
          this.navExpandMMProgram[0].children = [
            ...this.navExpandMMProgram[0].children!,
            {
              name: 'Customer Report',
              parentId: '6',
              id: '9',
              url: '/customer-report',
              icon: 'analytics',
            },
          ];
        }

        return hasMMMenuAccess;
      }),
    );
  }

  ngOnInit(): void {
    this.initRefreshTokenPolling();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        mergeMap(route => route.children[route.children.length - 1].data || route.firstChild!.data),
        takeUntil(this.destroyed$),
      )
      .subscribe(data => (this.pageData = (data as PageData) ?? { returnUrl: undefined, pageTitle: '' }));
  }

  onClose(returnUrl: string | undefined): void {
    if (returnUrl) {
      this.router.navigate([returnUrl]);
    }
  }

  onLogout(): void {
    manageCurrentAddressSessionStorage(undefined);
    this.onClearImpersonation();
    CookieAuth.logout();
  }

  onClearImpersonation(): void {
    this.store.dispatch(impersonationActions.clearPartner());
  }

  private initRefreshTokenPolling(): void {
    // Change to be 300 - it means that 5mins before AT expires it should try to refresh the token. AT should be alive for 1hour.
    this.subscribe(this.store.pipe(select(identitySelectors.selectTokenExpiration)), expires_at => {
      const currentTime = Date.now() / 1000; // Current time in seconds
      const timeUntilExpiration = expires_at! - currentTime; // Time until expiration in seconds
      if (timeUntilExpiration > 300) {
        const refreshTimeout = (timeUntilExpiration - 300) * 1000;
        setTimeout(() => {
          this.store.dispatch(identityActions.refresh());
        }, refreshTimeout);
      }
    });
  }
}
