<div class="main-container">
  <cui-app-container *ngIf="(isLoadingPermissions$ | async) === false">
    <cui-top-menu [backgroundColor]="topBgColor">
      <cui-top-menu-item
        *ngIf="hasShoppingCartMenuAccess$ | async"
        [iconName]="'shopping_cart'"
        [tooltipText]="'Shopping cart'"
        [routerLink]="'/cart'"
      ></cui-top-menu-item>
      <cui-top-menu-item
        *ngIf="user$ | async as user"
        [ngClass]="{ 'no-interaction': (hasSettingsMenuAccess$ | async) === false }"
        [iconName]="'settings'"
        [text]="user.email || 'Loading...'"
        [tooltipText]="'Settings'"
        [routerLink]="'/settings'"
      ></cui-top-menu-item>
      <cui-top-menu-item [iconName]="'logout'" [tooltipText]="'Log out'" (click)="onLogout()"></cui-top-menu-item>
    </cui-top-menu>
    <cui-side-nav-container>
      <ng-container class="body">
        <cui-side-nav-item icon="dashboard" url="/dashboard" [name]="'Dashboard'"></cui-side-nav-item>
        <cui-side-nav-item *ngIf="hasCustomersMenuAccess$ | async" icon="groups" url="/customers" [name]="'Customers'"></cui-side-nav-item>
        <cui-side-nav-expand *ngIf="hasMMMenuAccess$ | async" [navExpandNodes]="navExpandMMProgram"></cui-side-nav-expand>
        <cui-side-nav-expand *ngIf="hasSaasMenuAccess$ | async" [navExpandNodes]="navExpandSaas"></cui-side-nav-expand>
        <cui-side-nav-item *ngIf="hasProjectMenuAccess$ | async" icon="hive" url="/project" [name]="'Projects'"></cui-side-nav-item>
        <cui-side-nav-item *ngIf="hasSPMenuAccess$ | async" externalUrl="/selfprovisioning" icon="cloud" [name]="'Self provisioning'">
        </cui-side-nav-item>
        <cui-side-nav-expand *ngIf="hasInformationHubMenuAccess$ | async" [navExpandNodes]="navExpandInformationHub"></cui-side-nav-expand>
        <cui-side-nav-item *ngIf="hasSupportMenuAccess$ | async" icon="support_agent" url="/support" [name]="'Support'"></cui-side-nav-item>
        <cui-side-nav-item *ngIf="hasImpersonationMenuAccess$ | async" icon="people_alt" url="/impersonation" [name]="'Impersonation'">
        </cui-side-nav-item>
      </ng-container>
    </cui-side-nav-container>
    <div class="body">
      <cui-page-title
        *ngIf="pageData.pageTitle"
        [title]="pageData.pageTitle"
        [showClose]="PAGES_WITH_CLOSE_BTN.includes(pageData.pageTitle)"
        (close)="onClose(pageData.returnUrl)"
      ></cui-page-title>
      <div class="content-block-top">
        <div *ngIf="impersonatedPartner$ | async as partner">
          <span *ngIf="partner.id">
            ({{ partner.id }}) {{ partner.name }}
            <mat-icon (click)="onClearImpersonation()">cancel</mat-icon>
          </span>
        </div>
        <div [innerHTML]="(text$ | async)! | parseHtml"></div>
      </div>
      <router-outlet></router-outlet>
      <div [innerHTML]="(bottomText$ | async)! | parseHtml" class="content-block-bottom"></div>
    </div>
  </cui-app-container>
  <div *ngIf="(isLoadingPermissions$ | async) === true" class="app-pre-loading">
    <div>
      <img alt="Companial" id="app-logo" src="assets/companialLogo.svg" />
      <div id="app-loading-screen">
        <div class="progress-line"></div>
      </div>
    </div>
  </div>
</div>
