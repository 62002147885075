import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { createAction, props } from '@ngrx/store';
import { AddCustomerReportLineRequest, CustomerReport, ReportedCustomer } from './customer-report.model';
import { State } from './customer-report.reducer';

const NAMESPACE = '[MM Program Report]';

export const getCustomerReport = createAction(`${NAMESPACE} GetCustomerReport`);
export const getCustomerReportComplete = createAction(
  `${NAMESPACE} GetCustomerReportComplete`,
  props<{ customerReport: CustomerReport }>(),
);
export const getCustomerReportError = createAction(`${NAMESPACE} GetCustomerReportError`, props<{ error: HighlanderCoreErrorResponse }>());

export const getReportedCustomers = createAction(`${NAMESPACE} GetReportedCustomers`);
export const getReportedCustomersComplete = createAction(
  `${NAMESPACE} GetReportedCustomersComplete`,
  props<{ reportedCustomers: ReportedCustomer[] }>(),
);
export const getReportedCustomersError = createAction(
  `${NAMESPACE} GetReportedCustomersError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const submitCustomerReport = createAction(`${NAMESPACE} SubmitAssessment`, props<{ id: string }>());
export const submitCustomerReportComplete = createAction(
  `${NAMESPACE} SubmitAssessmentComplete`,
  props<{ customerReport: CustomerReport }>(),
);
export const submitCustomerReportError = createAction(
  `${NAMESPACE} SubmitAssessmentError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const addCustomerReportLine = createAction(
  `${NAMESPACE} AddCustomerReportLine`,
  props<{ id: string; data: AddCustomerReportLineRequest[] }>(),
);
export const addCustomerReportLineComplete = createAction(
  `${NAMESPACE} AddCustomerReportLineComplete`,
  props<{ customerReport: CustomerReport }>(),
);
export const addCustomerReportLineError = createAction(
  `${NAMESPACE} AddCustomerReportLineError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const deleteCustomerReportLine = createAction(`${NAMESPACE} DeleteCustomerReportLine`, props<{ id: string; lineId: string }>());
export const deleteCustomerReportLineComplete = createAction(`${NAMESPACE} DeleteCustomerReportLineComplete`, props<{ lineId: string }>());
export const deleteCustomerReportLineError = createAction(
  `${NAMESPACE} DeleteCustomerReportLineError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
