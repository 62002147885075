import { environment } from '@env/environment';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as accountReducer from './account/account.reducer';
import * as pageReducers from './active-pages/active-page.reducer';
import * as agreementsReducer from './agreements/agreements.reducer';
import * as announcementsReducer from './announcements/announcements.reducer';
import * as roleGroupsReducer from './authorization/role-groups/role-groups.reducer';
import * as rolesReducer from './authorization/roles/roles.reducer';
import * as cartItemsReducer from './cart/cart-items/cart-items.reducer';
import * as paymentInformationReducer from './cart/payment-information/payment-information.reducer';
import * as commonDataReducer from './common-data/common-data.reducer';
import * as countryReducers from './country/reducers';
import * as creditReducers from './credit/credit.reducer';
import * as customerReducer from './customers/customer/customer.reducer';
import * as customersListReducer from './customers/customers-list/customers-list.reducer';
import * as documentsReducer from './documents/documents-list/documents-list.reducer';
import * as environmentsReducer from './dynamics-arc/environments/environments.reducer';
import * as identityReducers from './identity/identity.reducer';
import * as impersonationReducer from './impersonation/impersonation.reducer';
import * as customerReportsReducer from './mm-program-report/customer-report.reducer';
import * as assessmentsListReducer from './mm-program/assessment/assessments-list/assessments-list.reducer';
import * as mmUtilsReducer from './mm-program/mm-util/mm-util.reducer';
import * as newsArticlesReducer from './news-articles/news-articles.reducer';
import * as notificationSubsReducer from './notification-subscriptions/notification-subs.reducer';
import * as partnerReducers from './partner/partner.reducer';
import * as partnersReducers from './partners/partners.reducer';
import { RouterState } from './router-state/router-state.model';
import * as stateReducers from './state/reducers';
import * as supportReducer from './support/support.reducer';
import * as userApplicationReducer from './user/user-anonymous/user-anonymous.reducer';
import * as userValidationReducer from './user/user-validation/user-validation.reducer';
import * as usersReducer from './user/users/users.reducer';
export interface AppState {
  router: RouterReducerState<RouterState>;
  accounts: accountReducer.State;
  activePages: pageReducers.State;
  partner: partnerReducers.State; // Depricated: will be replaced with Partners reducer.
  partners: partnersReducers.State;
  countries: countryReducers.State;
  states: stateReducers.StateState;
  identity: identityReducers.State;
  credit: creditReducers.State;
  cartItems: cartItemsReducer.State;
  paymentInformation: paymentInformationReducer.State;
  impersonation: impersonationReducer.State;
  documents: documentsReducer.State;
  support: supportReducer.State;
  commonData: commonDataReducer.State;
  roles: rolesReducer.State;
  roleGroups: roleGroupsReducer.State;
  userApplication: userApplicationReducer.State;
  userValidation: userValidationReducer.State;
  users: usersReducer.State;
  customer: customerReducer.State;
  customersList: customersListReducer.State;
  assessmentsList: assessmentsListReducer.State;
  announcements: announcementsReducer.State;
  agreements: agreementsReducer.State;
  newsArticles: newsArticlesReducer.State;
  environments: environmentsReducer.State;
  mmUtils: mmUtilsReducer.State;
  notificationSubs: notificationSubsReducer.State;
  customerReport: customerReportsReducer.State;
}

export const appReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  accounts: accountReducer.reducer,
  activePages: pageReducers.reducer,
  partner: partnerReducers.reducer,
  partners: partnersReducers.partnersReducer,
  countries: countryReducers.reducer,
  states: stateReducers.reducer,
  identity: identityReducers.reducer,
  credit: creditReducers.creditReducer,
  cartItems: cartItemsReducer.cartItemsReducer,
  paymentInformation: paymentInformationReducer.paymentInformationReducer,
  impersonation: impersonationReducer.impersonationReducer,
  documents: documentsReducer.documentsReducer,
  support: supportReducer.supportReducer,
  commonData: commonDataReducer.commonDataReducer,
  roles: rolesReducer.rolesReducer,
  roleGroups: roleGroupsReducer.roleGroupsReducer,
  userApplication: userApplicationReducer.userAnonymousReducer,
  userValidation: userValidationReducer.userValidationReducer,
  users: usersReducer.usersReducer,
  customer: customerReducer.customerReducer,
  customersList: customersListReducer.customersListReducer,
  assessmentsList: assessmentsListReducer.assessmentsListReducer,
  announcements: announcementsReducer.announcementsReducer,
  agreements: agreementsReducer.agreementsReducer,
  newsArticles: newsArticlesReducer.newsArticlesReducer,
  environments: environmentsReducer.environmentsReducer,
  mmUtils: mmUtilsReducer.mmUtilsReducer,
  notificationSubs: notificationSubsReducer.notificationSubsReducer,
  customerReport: customerReportsReducer.customerReportReducer,
};

export const getRootState = (s: AppState) => s;

export const metaReducers: MetaReducer<AppState>[] = !environment.debugMode
  ? []
  : [
      function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
        return <ActionReducer<AppState>>((state: AppState, action: Action): AppState => {
          const result = reducer(state, action);
          console.groupCollapsed(action.type);
          // eslint-disable-next-line
          console.debug('was', state);
          // eslint-disable-next-line
          console.debug('act', action);
          // eslint-disable-next-line
          console.debug('wil', result);
          console.groupEnd();
          return result;
        });
      },
    ];
