import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotificationsService } from 'cui-components';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as fromActions from './notification-subs.actions';
import { UserNotificationSubsService } from './notification-subs.service';

@Injectable()
export class NotificationSubscriptionsEffects {
  onQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.query),
      switchMap(() =>
        this.service.getSubscriptions().pipe(
          map(subs => fromActions.queryComplete({ subs })),
          catchError(() => of(fromActions.queryError())),
        ),
      ),
    ),
  );

  onSubscribeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.subscribeChange),
      switchMap(({ subs }) =>
        this.service.patchSubscriptions(subs).pipe(
          map(subs => fromActions.subscribeChangeComplete({ subs })),
          catchError(() => of(fromActions.subscribeChangeError())),
        ),
      ),
    ),
  );

  onGetUnsubscribeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUnsubscribeData),
      switchMap(({ data }) =>
        this.service.getUnsubscribeData(data).pipe(
          map(unsubscribeData => fromActions.getUnsubscribeDataComplete({ unsubscribeData })),
          catchError(() => of(fromActions.getUnsubscribeDataError())),
        ),
      ),
    ),
  );

  onUnsubscribeFromNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.unsubscribeFromNotification),
      switchMap(({ data }) =>
        this.service.unsubscribeFromNotification(data).pipe(
          map(() => fromActions.unsubscribeFromNotificationComplete()),
          catchError(() => of(fromActions.unsubscribeFromNotificationError())),
        ),
      ),
    ),
  );

  onUnsubscribeFromNotificationComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.unsubscribeFromNotificationComplete),
        tap(() => this.notificationService.showNotification('Unsubscribed successfully!')),
      ),
    { dispatch: false },
  );
  constructor(
    private actions$: Actions,
    private service: UserNotificationSubsService,
    private notificationService: NotificationsService,
  ) {}
}
