import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as fromActions from './news-articles.actions';
import { NewsArticlesService } from './news-articles.service';

@Injectable()
export class NewsArticlesEffects {
  onGetFilteredNewsArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFilteredNewsArticles),
      switchMap(({ filters }) =>
        this.newsArticlesService.getAll(filters).pipe(
          map(newsArticles => fromActions.getFilteredNewsArticlesComplete({ newsArticles, regionId: filters.regionId! })),
          catchError(err => of(fromActions.getFilteredNewsArticlesError({ err }))),
        ),
      ),
    ),
  );

  onGetPromotedNewsArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPromotedNewsArticles),
      switchMap(({ filters }) =>
        this.newsArticlesService.getAll(filters).pipe(
          map(newsArticles => fromActions.getPromotedNewsArticlesComplete({ newsArticles, regionId: filters.regionId! })),
          catchError(err => of(fromActions.getPromotedNewsArticlesError({ err }))),
        ),
      ),
    ),
  );

  onGetOpenedNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getOpenedNewsArticle),
      switchMap(({ id, regionId }) =>
        this.newsArticlesService.getOne(id, regionId).pipe(
          map(newsArticle => fromActions.getOpenedNewsArticleComplete({ newsArticle })),
          catchError(err => of(fromActions.getOpenedNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onGetOpenedNewsArticleError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getOpenedNewsArticleError),
      switchMap(() => of(fromActions.goToArticleList())),
    ),
  );

  onGoToArticleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToArticleList),
      tap(() => this.router.navigate(['news-articles'])),
      map(() => fromActions.resetState({ openedNewsArticle: null })),
    ),
  );

  onReactNewsArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.reactOnNewsArticle),
      switchMap(({ articleId, req }) =>
        this.newsArticlesService.reactOnArticle(articleId, req).pipe(
          map(newsArticle => fromActions.reactOnNewsArticleComplete({ newsArticle })),
          catchError(err => of(fromActions.reactOnNewsArticleError({ err }))),
        ),
      ),
    ),
  );

  onGetAllNewsArticleComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllNewsArticleComments),
      switchMap(({ articleId, regionId }) =>
        this.newsArticlesService.getAllComments(articleId, regionId).pipe(
          map(comments => fromActions.getAllNewsArticleCommentsComplete({ comments })),
          catchError(err => of(fromActions.getAllNewsArticleCommentsError({ err }))),
        ),
      ),
    ),
  );

  onCreateNewsArticleComment = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createNewsArticleComment),
      switchMap(({ articleId, req }) =>
        this.newsArticlesService.createComment(articleId, req).pipe(
          map(comment => fromActions.createNewsArticleCommentComplete({ comment })),
          catchError(err => of(fromActions.createNewsArticleCommentError({ err }))),
        ),
      ),
    ),
  );

  onReactNewsArticleComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.reactOnNewsArticleComment),
      switchMap(({ articleId, commentId, req }) =>
        this.newsArticlesService.reactOnComment(articleId, commentId, req).pipe(
          map(comment => fromActions.reactOnNewsArticleCommentComplete({ comment })),
          catchError(err => of(fromActions.reactOnNewsArticleCommentError({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly newsArticlesService: NewsArticlesService,
    private readonly router: Router,
  ) {}
}
