import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Environment,
  EnvironmentListItem,
  EnvironmentListItemResponse,
  EnvironmentResponse,
  SetUpdateDateRequest,
  SetUpdateWindowRequest,
  UpdateSchedulingResponse,
} from './environment.model';

@Injectable()
export class EnvironmentsService {
  private readonly url = 'environments';
  constructor(private highlanderService: HighlanderService) {}

  getAll(coreCustomerId?: string): Observable<EnvironmentListItem[]> {
    return this.highlanderService.get<EnvironmentListItemResponse[]>(this.url, { params: coreCustomerId ? { coreCustomerId } : {} });
  }

  getOne(environmentId: string): Observable<Environment> {
    return this.highlanderService.get<EnvironmentResponse>(`${this.url}/${environmentId}`);
  }

  setUpdateDate(environmentId: string, req: SetUpdateDateRequest): Observable<UpdateSchedulingResponse> {
    return this.highlanderService.post<SetUpdateDateRequest, UpdateSchedulingResponse>(`${this.url}/${environmentId}/set-update-date`, req);
  }

  setUpdateWindow(environmentId: string, req: SetUpdateWindowRequest): Observable<UpdateSchedulingResponse> {
    return this.highlanderService.post<SetUpdateWindowRequest, UpdateSchedulingResponse>(
      `${this.url}/${environmentId}/set-update-window`,
      req,
    );
  }
}
