import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './customer-report.actions';
import { CustomerReportService } from './customer-report.service';

@Injectable()
export class CustomerReportEffects {
  onGetCustomerReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCustomerReport),
      switchMap(() =>
        this.customerReportService.getCustomerReport().pipe(
          map(customerReport => fromActions.getCustomerReportComplete({ customerReport })),
          catchError(({ error }) => of(fromActions.getCustomerReportError({ error }))),
        ),
      ),
    ),
  );

  onGetReportedCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getReportedCustomers),
      switchMap(() =>
        this.customerReportService.getReportedCustomers().pipe(
          map(reportedCustomers => fromActions.getReportedCustomersComplete({ reportedCustomers })),
          catchError(({ error }) => of(fromActions.getReportedCustomersError({ error }))),
        ),
      ),
    ),
  );

  onSubmitCustomerReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitCustomerReport),
      switchMap(({ id }) =>
        this.customerReportService.submitCustomerReport(id).pipe(
          map(customerReport => fromActions.submitCustomerReportComplete({ customerReport })),
          catchError(({ error }) => of(fromActions.submitCustomerReportError({ error }))),
        ),
      ),
    ),
  );

  onShowSubmitCustomerReportComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitCustomerReportComplete),
      map(() => showCustomNotification({ message: `Customer report successfully submitted. Next period's report is now available.` })),
    ),
  );

  onAddCustomerReportLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerReportLine),
      switchMap(({ id, data }) =>
        this.customerReportService.addCustomerReportLine(id, data).pipe(
          map(customerReport => fromActions.addCustomerReportLineComplete({ customerReport })),
          catchError(({ error }) => of(fromActions.addCustomerReportLineError({ error }))),
        ),
      ),
    ),
  );

  onDeleteCustomerReportLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteCustomerReportLine),
      switchMap(({ id, lineId }) =>
        this.customerReportService.deleteCustomerReportLine(id, lineId).pipe(
          map(() => fromActions.deleteCustomerReportLineComplete({ lineId })),
          catchError(({ error }) => of(fromActions.deleteCustomerReportLineError({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly customerReportService: CustomerReportService,
  ) {}
}
