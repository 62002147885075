export const sortItems = <T>(array: T[], sorting: (a: T, b: T) => number): T[] => {
  return array.slice().sort((a, b) => sorting(a, b));
};

export function findAndRemove<T>(array: T[], predicate: (item: T) => boolean): T[] {
  const index = array.findIndex(predicate);
  const newArray = [...array];
  if (index !== -1) {
    newArray.splice(index, 1);
  }
  return newArray;
}
