import { createAction, props } from '@ngrx/store';
import { UnsubscribeData, UserNotificationSub } from './notification-subs.model';

const NAMESPACE = '[Notification Subscriptions]';

export const query = createAction(`${NAMESPACE} Query`);
export const queryComplete = createAction(`${NAMESPACE} QueryComplete`, props<{ subs: UserNotificationSub[] }>());
export const queryError = createAction(`${NAMESPACE} QueryError`);

export const subscribeChange = createAction(`${NAMESPACE} SubscribeChange`, props<{ subs: UserNotificationSub[] }>());
export const subscribeChangeComplete = createAction(`${NAMESPACE} SubscribeChangeComplete`, props<{ subs: UserNotificationSub[] }>());
export const subscribeChangeError = createAction(`${NAMESPACE} SubscribeChangeError`);

export const getUnsubscribeData = createAction(`${NAMESPACE} GetUnsubscribeData`, props<{ data: string }>());
export const getUnsubscribeDataComplete = createAction(
  `${NAMESPACE} GetUnsubscribeDataComplete`,
  props<{ unsubscribeData: UnsubscribeData }>(),
);
export const getUnsubscribeDataError = createAction(`${NAMESPACE} GetUnsubscribeDataError`);

export const unsubscribeFromNotification = createAction(`${NAMESPACE} UnsubscribeFromNotification`, props<{ data: string }>());
export const unsubscribeFromNotificationComplete = createAction(`${NAMESPACE} UnsubscribeFromNotificationComplete`);
export const unsubscribeFromNotificationError = createAction(`${NAMESPACE} UnsubscribeFromNotificationError`);
