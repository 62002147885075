import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

export const selectModuleState = createSelector(getRootState, s => s.notificationSubs);

export const selectIsLoading = createSelector(selectModuleState, s => s.isLoading);
export const selectIsUnsubscribeLoading = createSelector(selectModuleState, s => s.isLoadingUnsubscribeData);

export const selectSubs = createSelector(selectModuleState, s => s.subs);
export const selectUnsubscribeData = createSelector(selectModuleState, s => s.unsubscribeData);
