import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CuiButtonsModule, CuiCardModule } from 'cui-components';

export interface ConfirmationDialogData {
  title: string;
  confirmText: string;
  isConfirmDisabled?: boolean;
  cancelText: string;
  description: string;
  showCloseBtn?: boolean;
}

@Component({
  selector: 'hui-confirmation-dialog',
  template: `
    <cui-card>
      <cui-card-header [showCloseBtn]="showCloseBtn" (close)="onCancel()">
        <h3>
          <strong>{{ title }} </strong>
        </h3>
      </cui-card-header>
      <cui-card-body>
        {{ description }}
      </cui-card-body>
      <cui-card-footer>
        <cui-button *ngIf="cancelText" (clicked)="onCancel()">{{ cancelText }}</cui-button>
        <cui-button-cta *ngIf="confirmText" [disabled]="isConfirmDisabled" (clicked)="onConfirm()">{{ confirmText }}</cui-button-cta>
      </cui-card-footer>
    </cui-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CuiCardModule, CuiButtonsModule, CommonModule],
  styles: [
    `
      :host {
        min-width: fit-content;
        max-width: 80%;
        display: block;
        margin: auto;
      }
    `,
  ],
})
export class ConfirmationDialogComponent {
  readonly title: string;
  readonly confirmText: string;
  readonly cancelText: string;
  readonly description: string;
  readonly isConfirmDisabled: boolean;
  readonly showCloseBtn: boolean;

  constructor(private readonly dialogRef: DialogRef<boolean, ConfirmationDialogData>) {
    this.title = dialogRef.config.data.title;
    this.confirmText = dialogRef.config.data.confirmText;
    this.cancelText = dialogRef.config.data.cancelText;
    this.description = dialogRef.config.data.description;
    this.isConfirmDisabled = dialogRef.config.data.isConfirmDisabled ?? false;
    this.showCloseBtn = dialogRef.config.data.showCloseBtn ?? true;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
